import React from 'react'
import {
  SidebarEmailIcon,
  SidebarProfileIcon,
  SidebarBlankIcon,
  SidebarEcommerceIcon,
  SidebarChatIcon,
  SidebarTablesIcon,
  SidebarInvoiceIcon,
  SidebarContactsIcon,
  SidebarFormsIcon,
  SidebarScrumBoardIcon,
  SidebarChartsIcon,
  SidebarShuffleIcon,
  SideBarIoIosPeople,
  CardWidgetAchievementIcon,
  SideBarMissionIcon,
  SideBarPaymentsIcon,
  PackageIcon,
  CourseIcon,
  SideBarDepartmentIcon,
  SideBarPromoCodeIcon,
} from '@iso/config/icon.config'

const options = [
  {
    key: '/dashboard/admin/panel',
    label: 'Admin Panel',
    leftIcon: <SidebarShuffleIcon size={19} />
  },
  {
    key: '/dashboard/users',
    label: 'users',
    leftIcon: <SidebarProfileIcon size={19} />
  },



  {
    key: '/dashboard/audit',
    label: 'audit',
    leftIcon: <SidebarProfileIcon size={19} />,
    children: [
      {
        key: '/dashboard/audit/deposit',
        label: 'Deposit',
        leftIcon: <SidebarTablesIcon size={19} />
      },
      {
        key: '/dashboard/audit/withdraw',
        label: 'Withdraw',
        leftIcon: <SidebarTablesIcon size={19} />
      },
      {
        key: '/dashboard/audit/verification',
        label: 'Verification',
        leftIcon: <SidebarTablesIcon size={19} />
      }
    ]
  },



  {
    key: '/dashboard/subscribe_plan',
    label: 'Subscribed Plan',
    leftIcon: <CourseIcon size={19} />
  },
  {
    key: '/dashboard/accounts',
    label: 'MT5 Account',
    leftIcon: <SidebarProfileIcon size={19} />
  },
  {
    key: '/dashboard/Leaderboard',
    label: 'Leaderboard',
    leftIcon: <SidebarChartsIcon size={19} />
  },
  // {
  //   key: '/dashboard/ChallengePhase',
  //   label: 'Challenge Phase',
  //   leftIcon: <SidebarScrumBoardIcon size={19} />
  // },
  {
    key: '/dashboard/challenges',
    label: 'challenges',
    leftIcon: <SidebarProfileIcon size={19} />,
    children: [
      {
        key: '/dashboard/challenges/challenges',
        label: 'challenges.challenge'
      },
      {
        key: '/dashboard/challenges/addons',
        label: 'challenges.addons_list'
      },
      {
        key: '/dashboard/challenges/phases',
        label: 'challenges.phases'
      },
    ]
  },
  {
    key: '/dashboard/promocodes',
    label: 'promocodes',
    leftIcon: <SideBarPromoCodeIcon size={19} />
  },
  {
    key: '/dashboard/plan',
    label: 'Plan',
    leftIcon: <PackageIcon size={19} />
  },
  // {
  //   key: '/dashboard/admin/panel',
  //   label: 'Admin Panel',
  //   leftIcon: <SidebarShuffleIcon size={19} />
  // },

  // {
  //   key: '/dashboard/accounts',
  //   label: 'accounts',
  //   leftIcon: <SidebarFormsIcon size={19} />
  // },

  // {
  //   key: '/dashboard/audit',
  //   label: 'audit',
  //   leftIcon: <SidebarTablesIcon size={19} />,
  //   children: [
  //     // {
  //     //   key: '/dashboard/audit/deposit',
  //     //   label: 'deposit'
  //     // },
  //     // {
  //     //   key: '/dashboard/audit/withdraw',
  //     //   label: 'withdraw'
  //     // },
  //     // {
  //     //   key: '/dashboard/audit/transfer',
  //     //   label: 'transfer'
  //     // },
  //     // {
  //     //   key: '/dashboard/audit/account_withdraw',
  //     //   label: 'withdraw.account'
  //     // },
  //     // {
  //     //   key: '/dashboard/audit/account_transfer',
  //     //   label: 'transfer.account'
  //     // },
  //     // {
  //     //   key: '/dashboard/audit/verification',
  //     //   label: 'verification'
  //     // },
  //     // {
  //     //   key: '/dashboard/audit/bank',
  //     //   label: 'bank'
  //     // },
  //     // {
  //     //   key: '/dashboard/audit/credit_card',
  //     //   label: 'creditCard'
  //     // },
  //     // {
  //     //   key: '/dashboard/audit/bonus',
  //     //   label: 'bonus'
  //     // },
  //     // {
  //     //   key: '/dashboard/audit/leverage',
  //     //   label: 'leverage'
  //     // },
  //     // {
  //     //   key: '/dashboard/audit/commission',
  //     //   label: 'commission'
  //     // },
  //     {
  //       key: '/dashboard/audit/pamm_subscription',
  //       label: 'pamms.subscription'
  //     },
  //     {
  //       key: '/dashboard/audit/pamm_manager_application',
  //       label: 'pamms.application'
  //     },
  //     // {
  //     //   key: '/dashboard/audit/competition_registration',
  //     //   label: 'competition'
  //     // },
  //     {
  //       key: '/dashboard/audit/package_application',
  //       label: 'package_application'
  //     },
  //     {
  //       key: '/dashboard/audit/commission_payout',
  //       label: 'commission_payout'
  //     },
  //     // {
  //     //   key: '/dashboard/audit/client_referral_reward',
  //     //   label: 'referral_reward'
  //     // },
  //     // {
  //     //   key: '/dashboard/audit/promotion_record',
  //     //   label: 'promotion_record'
  //     // },
  //     // {
  //     //   key: '/dashboard/audit/event_participant',
  //     //   label: 'event_participant'
  //     // },
  //     // {
  //     //   key: '/dashboard/audit/voucher',
  //     //   label: 'voucher'
  //     // }
  //   ]
  // },
  // // {
  // //   key: 'transactions',
  // //   label: 'transactions',
  // //   leftIcon: <SidebarInvoiceIcon size={19} />,
  // //   children: [
  // //     {
  // //       key: 'transactions/deposit',
  // //       label: 'deposit'
  // //     },
  // //     {
  // //       key: 'transactions/withdraw',
  // //       label: 'withdraw'
  // //     },
  // //     {
  // //       key: 'transactions/transfer',
  // //       label: 'transfer'
  // //     }
  // //   ]
  // // },
  // {
  //   key: '/dashboard/clients',
  //   label: 'clients',
  //   leftIcon: <SidebarContactsIcon size={19} />
  // },
  // {
  //   key: 'pamms',
  //   label: 'pamms',
  //   leftIcon: <SideBarIoIosPeople size={19} />,
  //   children: [
  //     {
  //       key: '/dashboard/pamms/managers',
  //       label: 'pamms.manager'
  //     },
  //     {
  //       key: '/dashboard/pamms/subscriptions',
  //       label: 'pamms.subscription'
  //     }
  //   ]
  // },
  // // {
  // //   key: "staff",
  // //   label: "staff",
  // //   leftIcon: <SidebarBlankIcon size={19} />,
  // // },
  // // {
  // //   key: "teams",
  // //   label: "teams",
  // //   leftIcon: <SidebarBlankIcon size={19} />,
  // // },
  // // {
  // //   key: '/dashboard/currencies',
  // //   label: 'currencies',
  // //   leftIcon: <SidebarChartsIcon size={19} />
  // // },
  // // {
  // //   key: '/dashboard/ib_settings',
  // //   label: 'settings.ib',
  // //   leftIcon: <SidebarScrumBoardIcon size={19} />,
  // //   children: [
  // //     {
  // //       key: '/dashboard/ib_settings/groups',
  // //       label: 'groups'
  // //     },
  // //     {
  // //       key: '/dashboard/ib_settings/partners',
  // //       label: 'partners'
  // //     },
  // //     {
  // //       key: '/dashboard/ib_settings/teams',
  // //       label: 'teams'
  // //     }
  // //   ]
  // // },
  // // {
  // //   key: '/dashboard/mail_settings',
  // //   label: 'settings.mail',
  // //   leftIcon: <SidebarEmailIcon size={19} />
  // // },
  // // {
  // //   key: '/dashboard/payments',
  // //   label: 'payments',
  // //   leftIcon: <SideBarPaymentsIcon size={19} />
  // // },
  // {
  //   key: '/dashboard/support_tickets',
  //   label: 'support.tickets',
  //   leftIcon: <SidebarChatIcon size={19} />
  // },
  // // {
  // //   key: '/dashboard/client_referral_missions',
  // //   label: 'client_referral_missions',
  // //   leftIcon: <SideBarMissionIcon size={19} />
  // // },
  // // {
  // //   key: '/dashboard/competitions',
  // //   label: 'competitions',
  // //   leftIcon: <CardWidgetAchievementIcon size={19} />
  // // },
  // // {
  // //   key: '/dashboard/promotions',
  // //   label: 'promotions',
  // //   leftIcon: <SidebarEcommerceIcon size={19} />
  // // },
  // // {
  // //   key: '/dashboard/promocodes',
  // //   label: 'promocodes',
  // //   leftIcon: <SidebarEcommerceIcon size={19} />
  // // },
  // {
  //   key: '/dashboard/packages',
  //   label: 'packages',
  //   leftIcon: <PackageIcon size={19} />
  // },
  // {
  //   key: '/dashboard/courses',
  //   label: 'courses',
  //   leftIcon: <CourseIcon size={19} />
  // },
  // {
  //   key: '/dashboard/program_departments',
  //   label: 'program_departments',
  //   leftIcon: <SideBarDepartmentIcon size={19} />
  // },
  // // {
  // //   key: '/dashboard/legals',
  // //   label: 'legals',
  // //   leftIcon: <SidebarFormsIcon size={19} />
  // // },
  // // {
  // //   key: '/dashboard/announcements',
  // //   label: 'announcements',
  // //   leftIcon: <SidebarFormsIcon size={19} />
  // // },
  // // {
  // //   key: '/dashboard/settings',
  // //   label: 'settings',
  // //   leftIcon: <SidebarShuffleIcon size={19} />
  // // },
  {
    key: '/dashboard/reasons',
    label: 'reasons',
    leftIcon: <SidebarShuffleIcon size={19} />
  },
  // // {
  // //   key: '/dashboard/crm_feedbacks',
  // //   label: 'crm.feedbacks',
  // //   leftIcon: <SidebarShuffleIcon size={19} />
  // // }
  {
    key: '/dashboard/affiliate',
    label: 'Affiliate',
    leftIcon: <SidebarProfileIcon size={19} />,
    children: [
      
      {
        key: '/dashboard/affiliate/affiliate_list',
        label: 'Affiliate List'
      },
      {
        key: '/dashboard/affiliate/affiliate_rules',
        label: 'Affiliate Rules'
      },
      {
        key: '/dashboard/affiliate/affiliate_earning',
        label: 'Affiliate Earnings'
      },
      {
        key: '/dashboard/affiliate/withdraw',
        label: 'Withdraw'
      }
    ]
  },
]
export default options
